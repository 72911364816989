import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { HttpResponse } from '../../../core/interfaces/http.inteface';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { LovHome, Workspace, WorkspaceSystemInfo, WorkspaceUser } from './workspace.interface';
import { AuthService } from '../../../core/services/auth/auth.service';
import { RequestAction } from "../../../core/interfaces/action.interface";
import { get2HoursLaterEpoch } from "../../../core/helpers/date.helper";
import { changeToParam } from '../../../core/helpers/http.helper';
import { LOV_URL } from '../../../core/constants/url.const';
import { sortSequence } from '../../../core/helpers/sort.helper';

@Injectable({
    providedIn: 'root'
})
export class WorkspaceService {

    baseUrl = `${ environment.API_URL }/api/v1/core/home`;
    workspace$: BehaviorSubject<Workspace> = new BehaviorSubject<Workspace>(null);

    constructor(private http: HttpClient,
                private authService: AuthService,) {
    }

    getUser(username: string) {
        return this.http.get<HttpResponse<WorkspaceUser>>(`${ this.baseUrl }/${ username }`).pipe(map((resp) => {
            resp.data.profileImageFile = 'data:image/png;base64, ' + resp.data.profileImageFile;
            resp.data.sessionExpired = get2HoursLaterEpoch();
            return resp;
        }));
    }

    getTask() {
        const username = this.authService.user$.value.userName;
        return this.http.get<HttpResponse<Workspace>>(`${ this.baseUrl }/${ username }/tasks`);
    }

    getSystemInfo(unfilteredParams: any) {
        const username = this.authService.user$.value.userName;
        const params = changeToParam(unfilteredParams);
        return this.http.get<HttpResponse<WorkspaceSystemInfo>>(`${ this.baseUrl }/${ username }/system-info`, { params });
    }

    updateNotification(body: Partial<RequestAction>) {
        const headers = new HttpHeaders({
            disabledToast: 'true'
        });
        return this.http.post<HttpResponse<null>>(`${ this.baseUrl }/notifications`, body, { headers });
    }

    getUsername() {
        return this.workspace$.value?.userName || '';
    }


    getLov(): Observable<HttpResponse<LovHome>> {
        const params = { tableType: 'LOV_HOME' };
        return this.http.get<any>(LOV_URL, { params })
            .pipe(map((resp) => {
                let lov = resp.data.sort(sortSequence);
                const data: LovHome = {
                    allList: lov,
                }
                resp.data = data;
                return resp;
            }));
    }
}
